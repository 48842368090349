@font-face {
  font-family: "Source Han Serif CN";
  src: local("Source Han Serif CN"),
    url(../fonts/SourceHanSerifCN-Regular.otf) format("opentype");
  font-display: block;
}

@font-face {
  font-family: "Source Han Sans CN";
  src: local("Source Han Sans CN"),
    url(../fonts/SourceHanSansCN-Regular.otf) format("opentype");
  font-display: block;
}
