[data-sort-input-item-dropping] {
  position: relative;
}

[data-sort-input-item-dropping]:after {
  content: " ";
  position: absolute;
  left: 0;
  right: 0;
  height: 2px;
  background-color: #ccc;
  z-index: 1;
}

[data-sort-input-item-dropping="HEAD"]:after {
  top: 0;
}

[data-sort-input-item-dropping="TAIL"]:after {
  bottom: 0;
}
