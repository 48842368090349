.markdown img {
  transform-origin: top left;
  transform: scale(0.25);
}
.markdown figure {
  margin: 10px;
}
.markdown p,
.markdown ul {
  margin: 5px 0;
}
