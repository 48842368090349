body[data-fms-loading] * {
  cursor: progress !important;
}

body[data-fms-blocking] * {
  cursor: wait !important;
}

body {
  font-size: 14px;
}

@media print {
  .no-print,
  .no-print * {
    display: none !important;
  }
}
